import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { change, Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { RETURN_TO_CONSIGNOR } from "../../../../constants/analytics";
import {
  ReturnToConsignor as Fields,
  UK_POSTCODE_MAX_LENGTH,
} from "../../../../constants/forms";
import * as M from "../../../../constants/strings";
import PostcodeFinderInput from "../../../../features/PostcodeFinder/PostcodeFinderInput";
import { isParcelCode } from "../../../../models/parcel";
import { deliveryEditValidators } from "../../../../models/validators";
import {
  fetchReturnAddress,
  onAddressChange,
  onReturnToConsignor,
} from "../../actions";
import CustomCheckbox from "../../components/CustomCheckbox";
import EditPanel from "../../components/EditPanel";
import {
  hadleInputFocus,
  withParcelEdit,
  withRelatedParcelsInitialValues,
} from "../../hocs";
import {
  getParcelFormValues,
  getReturnAddresses,
  getReturnKeyValueAddresses,
} from "../../selectors";
import { formatDataForRequest, formInitialValues } from "./model";
import { toUppercaseValues } from "../../../../utils/object";

const ReturnToSender = ({
  handleSubmit,
  onSubmit,
  error,
  addressesKeyValue,
  parcel,
  onClickAddress,
  onSelectAddressFromModal,
  onFieldEntry,
  formValues,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={M.RETURN_TO_SENDER}>
      <Row>
        <Col className="col-12 col-md-6">
          <Field
            autoFocus={false}
            component={FormControl.Dropdown}
            values={addressesKeyValue}
            onChange={onClickAddress}
            placeholder={M.RETURN_ADDRESS.toUpperCase()}
            name={Fields.ADDRESS}
            label={M.SELECT_ADDRESS}
          />
          <Field
            autoFocus={false}
            name={Fields.COUNTRY_NAME}
            label={M.COUNTRY}
            component={FormControl.Input}
            disabled
          />
          <Field
            autoFocus={false}
            name={Fields.POSTCODE}
            component={PostcodeFinderInput}
            label={M.POSTCODE}
            maxLength={UK_POSTCODE_MAX_LENGTH}
            onSelectionChange={onSelectAddressFromModal}
            onBlur={onFieldEntry}
            disabled
            id={Fields.POSTCODE}
          />
          <Field
            autoFocus={false}
            name={Fields.NAME}
            label={M.NAME}
            component={FormControl.Input}
            maxLength={35}
            helperText={M.MAX_35_CHARACTERS}
            disabled
          />
          <Field
            autoFocus={false}
            name={Fields.STREET}
            label={M.STREET}
            component={FormControl.Input}
            disabled
          />
        </Col>
        <Col className="col-12 col-md-6">
          <Field
            autoFocus={false}
            name={Fields.SUBURB}
            label={M.SUBURB}
            component={FormControl.Input}
            disabled
          />
          <Field
            autoFocus={false}
            name={Fields.TOWN}
            label={M.TOWN}
            component={FormControl.Input}
            maxLength={35}
            helperText={M.MAX_35_CHARACTERS}
            disabled
          />
          <Field
            autoFocus={false}
            name={Fields.CITY}
            label={M.CITY}
            component={FormControl.Input}
            maxLength={35}
            helperText={M.MAX_35_CHARACTERS}
            disabled
          />
          <Field
            autoFocus={false}
            name={Fields.COUNTY}
            label={M.COUNTY}
            component={FormControl.Input}
            maxLength={35}
            helperText={M.MAX_35_CHARACTERS}
            disabled
          />
          <Field
            autoFocus={false}
            name={Fields.REF}
            label={M.REFERENCE}
            component={FormControl.Input}
            maxLength={35}
            helperText={M.MAX_35_CHARACTERS}
          />
        </Col>
      </Row>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        additionalInfoComponent={
          <Field
            component={CustomCheckbox}
            name={Fields.IS_INTERNATIONAL_PARCEL}
            label={M.ACCEPT_COST}
            id={Fields.IS_INTERNATIONAL_PARCEL}
            classes={{ container: "pl-0" }}
          />
        }
        {...rest}
      />
    </CardWithTitle>
  </Col>
);

ReturnToSender.defaultProps = {
  onSubmit: () => null,
};

ReturnToSender.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  addresses: PropTypes.array,
  addressesKeyValue: PropTypes.array,
  onClickAddress: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  withRelatedParcelsInitialValues,
  withAppLoader({
    loadFn: ({ dispatch, match }, fetchOptions) =>
      dispatch(fetchReturnAddress(match.params.parcelCode, fetchOptions)),
  }),
  connect(
    state => ({
      addresses: getReturnAddresses(state),
      addressesKeyValue: getReturnKeyValueAddresses(state),
      formValues: getParcelFormValues(state),
    }),
    (dispatch, { form, parcel }) => ({
      onSelectAddressFromModal: ({ postcode }, fieldName = Fields.POSTCODE) =>
        dispatch(change(form, fieldName, postcode)),
      onClickAddress: (e, value) =>
        dispatch(
          onAddressChange(value, parcel.deliveryDetails.notificationDetails)
        ),
    })
  ),
  withProps(({ additionalInitialValues, addresses }) => ({
    additionalInitialValues: {
      ...additionalInitialValues,
      ...formInitialValues,
    },
    initialAddressValues: {
      ...(addresses.length === 1 && {
        ...toUppercaseValues(addresses[0]),
        [Fields.ADDRESS]: "0",
      }),
    },
  })),
  withParcelEdit({
    onSubmit: (parcelCode, values, parcel) =>
      onReturnToConsignor(parcelCode, formatDataForRequest(values, parcel)),
    validate: deliveryEditValidators.returnToConsignor,
  }),
  withTrack(RETURN_TO_CONSIGNOR.LOAD),
  withTrackProps({
    onSubmit: RETURN_TO_CONSIGNOR.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      const field = isParcelCode(fieldName) ? Fields.PARCEL_CODE : fieldName;
      switch (field) {
        case Fields.ADDRESS:
          return RETURN_TO_CONSIGNOR.CLICK_DROP_DOWN_LIST;
        case Fields.IS_INTERNATIONAL_PARCEL:
          return RETURN_TO_CONSIGNOR.CLICK_TERMS_CHECKBOX;
        case Fields.REF:
          return RETURN_TO_CONSIGNOR.REFERENCE_INPUT;
        case Fields.INSTRUCTIONS:
          return RETURN_TO_CONSIGNOR.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return RETURN_TO_CONSIGNOR.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return RETURN_TO_CONSIGNOR.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return RETURN_TO_CONSIGNOR.EMAIL_ADDRESS_INPUT;
        case Fields.ALL_CHECKBOXES:
          return RETURN_TO_CONSIGNOR.CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL;
        case Fields.PARCEL_CODE:
          return RETURN_TO_CONSIGNOR.CLICK_PARCEL_NO_CHECKBOX;
        default:
          break;
      }
    },
    onClickAddress: RETURN_TO_CONSIGNOR.CLICK_ADDRESS_DESCRIPTION,
  }),
  hadleInputFocus
)(ReturnToSender);
