import { get, find, isString, isEmpty, some } from "lodash";

import { REGEX } from "@dpdgroupuk/mydpd-joi-validator";

import { INTERNAL_COUNTRY_CODES } from "../constants/address";
import { FIRST_TWO_CHARACTERS } from "./validators/regex";

const BIC7_OPTIONS = {
  A: "([A-Z])",
  B: "([A-Z])",
  N: "([0-9])",
  O: "([0-9])",
  "?": "([A-Z])([0-9])",
  X: "([A-Z])([0-9])",
};

const generateRegexForBIC7PostCodePattern = (bic7PostCodePattern = "") => {
  const regex = Array.from(bic7PostCodePattern).reduce(
    (acc, char) => (acc += BIC7_OPTIONS[char]),
    "^"
  );
  return regex.concat("$");
};

const generateRegexPatterns = postcodePattern => {
  const patterns = !Array.isArray(postcodePattern)
    ? Array.from(postcodePattern).split(",")
    : postcodePattern;

  return patterns
    .filter(pattern => isString(pattern))
    .map(pattern => generateRegexForBIC7PostCodePattern(pattern));
};

export const checkIfPostcodeRequired = country => {
  const postcodePattern = get(country, "postcodePattern", []);

  const isPostcodePatternRequired = !some(postcodePattern, pattern =>
    isEmpty(pattern.trim())
  );

  return isPostcodePatternRequired && country.zipLength > 0;
};

const checkIfInternationalPostcodePatternValid = (
  postcode,
  postcodePattern
) => {
  const regexPatterns = generateRegexPatterns(postcodePattern);
  return regexPatterns.some(pattern => RegExp(pattern).test(postcode));
};

const checkIfInternationalPostcodeValid = ({ postcode, country }) => {
  const flagPostCodeNo = get(country, "flagPostCodeNo", "");
  const postcodePattern = get(country, "postcodePattern", "");
  const countryCode = get(country, "countryKey", "");

  if (flagPostCodeNo) {
    return true;
  }

  if (
    country?.internalCode === INTERNAL_COUNTRY_CODES.US &&
    postcode.match(FIRST_TWO_CHARACTERS)
  ) {
    const postcodeWithoutFirstTwoCharacters = postcode.substring(2);
    return checkIfInternationalPostcodePatternValid(
      postcodeWithoutFirstTwoCharacters,
      postcodePattern
    );
  }

  const postcodeWithoutCountryCode = postcode.startsWith(countryCode)
    ? postcode.substring(2)
    : postcode;
  return checkIfInternationalPostcodePatternValid(
    postcodeWithoutCountryCode,
    postcodePattern
  );
};

export const checkIfPostcodeValid = ({ postcode, countryCode, countries }) => {
  const country = find(countries, { internalCode: countryCode });
  const isPostcodeRequired = checkIfPostcodeRequired(country);

  if (!isPostcodeRequired) {
    return true;
  }

  if (countryCode === INTERNAL_COUNTRY_CODES.UK) {
    if (REGEX.GB_POSTCODE_PATTERN.test(postcode)) {
      return true;
    }

    return false;
  }

  if (countryCode === INTERNAL_COUNTRY_CODES.EI) {
    if (REGEX.IE_POSTCODE_PATTERN.test(postcode)) {
      return true;
    }

    return false;
  }

  return checkIfInternationalPostcodeValid({
    postcode,
    country,
  });
};
