import { keys, zipWith } from "lodash";

import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import { isParcelCode } from "../../../../models/parcel";
import { DESTINATION_CODES } from "../../../../constants/address";

export const formInitialValues = {
  deliveryAddressDescription: "",
  deliveryCity: "",
  deliveryCountryCode: "",
  deliveryCountryName: "",
  deliveryCounty: "",
  deliveryName: "",
  deliveryPostcode: "",
  deliveryStreet: "",
  deliverySuburb: "",
  deliveryTown: "",
  contactName: "",
  email: "",
  mobile: "",
  allCheckboxes: true,
  isInternationalParcel: false,
};

export const getRelatedParcelsInitialValues = (parcels, value = false) => {
  if (parcels.length > 0) {
    const parcelsCodes = [{}, ...parcels].reduce(
      (accumulator = {}, parcel, index) => ({
        ...(index === 1 && accumulator.parcelCode
          ? { [accumulator.parcelCode]: value }
          : accumulator),
        [parcel.parcelCode]: value,
      })
    );
    return {
      ...parcelsCodes,
      allCheckboxes: value,
    };
  }
  return [];
};

export const formatAddresses = ({
  contactName,
  deliveryAddressDescription,
  deliveryCity,
  deliveryCountryCode,
  deliveryCountryName,
  deliveryCounty,
  deliveryPostcode,
  deliveryStreet,
  deliveryName,
  deliverySuburb,
  deliveryTown,
  notificationEmail,
  notificationPhone,
}) =>
  zipWith(
    contactName,
    deliveryAddressDescription,
    deliveryCity,
    deliveryCountryCode,
    deliveryCountryName,
    deliveryCounty,
    deliveryPostcode,
    deliveryName,
    deliveryStreet,
    deliverySuburb,
    deliveryTown,
    notificationEmail,
    notificationPhone,
    (
      contactName,
      deliveryAddressDescription,
      deliveryCity,
      deliveryCountryCode,
      deliveryCountryName,
      deliveryCounty,
      deliveryPostcode,
      deliveryName,
      deliveryStreet,
      deliverySuburb,
      deliveryTown,
      email,
      mobile
    ) => ({
      contactName,
      deliveryAddressDescription,
      deliveryCity,
      deliveryCountryCode,
      deliveryCountryName,
      deliveryCounty,
      deliveryPostcode,
      deliveryName,
      deliveryStreet,
      deliverySuburb,
      deliveryTown,
      email,
      mobile,
    })
  );

export const formatDataForRequest = (values, { destinationTypeCode }) => {
  const {
    contactName,
    email,
    mobile,
    deliveryCity,
    deliveryName,
    deliveryCountryCode,
    deliveryCounty,
    deliveryPostcode,
    deliveryStreet,
    deliverySuburb,
    deliveryTown,
    instructions,
    sendersReference,
    ...rest
  } = values;

  const parcelCodes = keys(rest).filter(isParcelCode);

  return {
    notificationDetails: {
      contactName,
      email,
      mobile,
    },
    address: {
      city: deliveryCity,
      organisation: deliveryName,
      countryCode: deliveryCountryCode,
      county: deliveryCounty,
      postcode: deliveryPostcode,
      street: deliveryStreet,
      locality: deliverySuburb,
      town: deliveryTown,
    },
    parcelCodes,
    instructions,
    sendersReference,
    actionCode:
      destinationTypeCode === DESTINATION_CODES.INTERNATIONAL
        ? PARCEL_ACTION_CODE.RTI
        : PARCEL_ACTION_CODE.RTC,
  };
};
