import moment from "moment";

import { DEFAULT_DATE_FORMAT } from "../../constants/dateFormats";
import { Fields } from "../../constants/forms";
import { isDateWithinLast10Months, isValidDate } from "./date";
import { PHONE_NUMBER } from "./regex";

const dateFormat = DEFAULT_DATE_FORMAT;
const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9-]{1,}[A-Za-z0-9]))$/;

// eslint-disable-next-line import/no-anonymous-default-export
export default values => {
  const errors = {};
  if (!values.type) {
    errors[Fields.TYPE] = "Please select a value for Search By";
  }

  if (
    values.type === Fields.DELIVERY_REFERENCE &&
    !values[Fields.DELIVERY_REFERENCE]
  ) {
    errors[Fields.DELIVERY_REFERENCE] =
      "Please enter a value for Parcel Reference";
  }

  if (
    values.type === Fields.DELIVERY_REFERENCE &&
    values[Fields.DELIVERY_REFERENCE] &&
    values[Fields.DELIVERY_REFERENCE].split("\n").length > 30
  ) {
    errors[
      Fields.DELIVERY_REFERENCE
    ] = `Parcel Reference cannot exceed 30 Parcel Numbers, 
    you currently have ${
      values[Fields.DELIVERY_REFERENCE].split("\n").length
    } parcels entered`;
  } else if (
    values.type === Fields.DELIVERY_REFERENCE &&
    values[Fields.DELIVERY_REFERENCE]
  ) {
    const parcels = values[Fields.DELIVERY_REFERENCE].trim().split("\n");
    parcels.forEach(parcel => {
      const parcelNumberLength = parcel.trim().length;
      if (parcelNumberLength < 8) {
        errors[Fields.DELIVERY_REFERENCE] =
          "Parcel Reference value is below 8 characters";
      } else if (parcelNumberLength > 28) {
        errors[Fields.DELIVERY_REFERENCE] =
          "Parcel Reference value is over 28 characters";
      }
    });
  }

  if (values.type === Fields.REFERENCE && !values[Fields.REFERENCE]) {
    errors[Fields.REFERENCE] = "Please enter a value for Customer Reference";
  }

  if (
    values.type === Fields.COLLECTION_CODE &&
    !values[Fields.COLLECTION_CODE]
  ) {
    errors[Fields.COLLECTION_CODE] =
      "Please enter a value for Collection Number";
  }

  if (
    values.type === Fields.COLLECTION_CODE &&
    values[Fields.COLLECTION_CODE] &&
    values[Fields.COLLECTION_CODE].length < 10
  ) {
    errors[Fields.COLLECTION_CODE] =
      "Collection No value is less than 10 characters";
  }

  if (
    values.type === Fields.COLLECTION_CODE &&
    values[Fields.COLLECTION_CODE] &&
    values[Fields.COLLECTION_CODE].length > 12
  ) {
    errors[Fields.COLLECTION_CODE] =
      "Collection Number value is above 12 characters";
  }

  if (values.type === Fields.CASE_CODE && !values[Fields.CASE_CODE]) {
    errors[Fields.CASE_CODE] = "Please enter a value for Case Reference";
  }

  if (
    values.type === Fields.CASE_CODE &&
    values[Fields.CASE_CODE] &&
    !/^[a-zA-Z0-9]+$/.test(values[Fields.CASE_CODE])
  ) {
    errors[Fields.CASE_CODE] = "Case Reference contains invalid characters";
  }

  if (values.type === Fields.EMAIL && !values[Fields.EMAIL]) {
    errors[Fields.EMAIL] = "Please enter a value for Email Address";
  }

  if (
    values.type === Fields.EMAIL &&
    values[Fields.EMAIL] &&
    !values[Fields.EMAIL].match(EMAIL_PATTERN)
  ) {
    errors[Fields.EMAIL] = "Invalid Email Address";
  }

  if (values.type === Fields.PHONE && !values[Fields.PHONE]) {
    errors[Fields.PHONE] = "Please enter a value for Phone Number";
  }

  if (
    values.type === Fields.PHONE &&
    values[Fields.PHONE] &&
    !values[Fields.PHONE].match(PHONE_NUMBER)
  ) {
    errors[Fields.PHONE] = "Invalid Phone Number";
  }

  if (values.type === Fields.POSTCODE && !values[Fields.POSTCODE]) {
    errors[Fields.POSTCODE] = "Please enter a value for Postcode";
  }

  if (values.type === Fields.DATE_TYPE) {
    if (!values[Fields.DATE_TYPE] && !values[Fields.DATE_TYPE]) {
      errors[Fields.DATE_TYPE] = "Please select a value";
    }

    const fromDateField = Fields.FROM_DATE;
    const toDateField = Fields.TO_DATE;

    if (
      (!values[fromDateField] && !values[toDateField]) ||
      !isValidDate(values[fromDateField])
    ) {
      errors[fromDateField] = "Please enter a value for From Date";
    }
    if (
      (!values[fromDateField] && !values[toDateField]) ||
      !isValidDate(values[toDateField])
    ) {
      errors[toDateField] = "Please enter a value for To Date";
    }

    if (values[fromDateField]) {
      if (!isValidDate(values[fromDateField])) {
        errors[fromDateField] = "Please enter a valid value for From Date";
      }
      if (!isDateWithinLast10Months(values[fromDateField])) {
        errors[fromDateField] = "Please enter a date within the last 10 months";
      }
    }

    if (
      values[fromDateField] &&
      values[toDateField] &&
      moment(values[toDateField], dateFormat, true).diff(
        moment(values[fromDateField], dateFormat, true),
        "days"
      ) > 6
    ) {
      errors[fromDateField] = "Dates must be within 7 days range";
    }

    if (
      values[fromDateField] &&
      values[toDateField] &&
      moment(values[toDateField], dateFormat, true).diff(
        moment(values[fromDateField], dateFormat, true),
        "days"
      ) < 0
    ) {
      errors[toDateField] = "To Date must be greater than From Date";
    }
  }

  return errors;
};
