import * as M from "./strings";

export const DELIVERY_REFERENCE_SEARCH_FORM = "DELIVERY_REFERENCE_SEARCH_FORM";
export const SEARCH_FORM = "SEARCH_FORM";
export const DELIVERIES_SEARCH_FILTER_FORM = "DELIVERIES_SEARCH_FILTER_FORM";
export const SEND_EMAIL_FORM = "SEND_EMAIL_FORM";
export const DASHBOARD_FORM = "DASHBOARD_FORM";
export const PARCEL_EDIT_FORM = "PARCEL_EDIT_FORM";
export const WATCH_LIST_FORM = "WATCH_LIST_FORM";
export const SERVICE_DISRUPTION_FORM = "SERVICE_DISRUPTION_FORM";

export const UK_POSTCODE_MAX_LENGTH = 8;
export const INTERNATIONAL_POSTCODE_MAX_LENGTH = 10;

// Fields

export const Fields = {
  ACCOUNT: "account",
  AGREEMENT: "agreement",
  DATE: "date",
  REFERENCE: "customerReference",
  CALLING_CARD_REFERENCE: "collectionReference",
  DATE_TYPE: "dateType",
  COLLECTION_CODE: "collectionNumber",
  FROM_DATE: "startDate",
  TO_DATE: "endDate",
  EMAIL: "email",
  PHONE: "phoneNumber",
  POSTCODE: "postcode",
  POSTCODE_EMAIL: "postcodeEmail",
  POSTCODE_DATE: "postcodeDate",
  POSTCODE_CUSTOMER_REFERENCE: "postcodeCustomerReference",
  POSTCODE_PHONE: "postcodePhone",
  PARTIAL_POSTCODE: "partialPostcode",
  ORGANISATION: "organisation",
  PROPERTY: "property",
  STREET: "street",
  LOCALITY: "locality",
  TOWN: "town",
  COUNTY: "county",
  CONTACT_NAME: "contactName",
  COLLECTION_START_DATE: "collectionStartDate",
  COLLECTION_END_DATE: "collectionEndDate",
  DELIVERY_START_DATE: "deliveryStartDate",
  DELIVERY_END_DATE: "deliveryEndDate",
  SHIPPED_START_DATE: "shippedStartDate",
  SHIPPED_END_DATE: "shippedEndDate",
  TARGET_START_DATE: "targetDeliveryStartDate",
  TARGET_END_DATE: "targetDeliveryEndDate",
  CREATE_START_DATE: "createStartDate",
  CREATE_END_DATE: "createEndDate",
  DELIVERY_REFERENCE: "parcelReference",
  CASE_CODE: "caseReference",
  MOBILE: "mobile",
  SAFE_PLACE_CODE: "safePlaceCode",
  DELIVERY_DATE: "deliveryDate",
  INSTRUCTIONS: "instructions",
  ADDRESS: "neighbourAddress",
  COUNTRY: "country",
  NAME: "name",
  SUBURB: "suburb",
  CITY: "city",
  REFERENCE_CHANGE_ADDRESS: "sendersReference",
  NOTES: "notes",
  TYPE: "type",
  DESTINATION_TYPE_CODE: "destinationTypeCode",
};

export const SEARCH_TYPE_LIST = [
  {
    label: M.DELIVERY_REFERENCE,
    value: Fields.DELIVERY_REFERENCE,
  },
  {
    label: M.POSTCODE,
    value: Fields.POSTCODE,
  },
  {
    label: M.COLLECTION_CODE,
    value: Fields.COLLECTION_CODE,
  },
  {
    label: M.CASE_CODE,
    value: Fields.CASE_CODE,
  },
  {
    label: M.CUSTOMER_REFERENCE,
    value: Fields.REFERENCE,
  },
  {
    label: M.EMAIL_ADDRESS,
    value: Fields.EMAIL,
  },
  {
    label: M.PHONE_NUMBER,
    value: Fields.PHONE,
  },
  {
    label: M.DATE,
    value: Fields.DATE_TYPE,
  },
];

export const DATE_TYPES = {
  COLLECTION: "collection",
  DELIVERY: "delivery",
  SHIPPED: "shipped",
  TARGET_DELIVERY: "targetDelivery",
};

export const DATE_TYPES_OPTIONS = [
  {
    value: DATE_TYPES.COLLECTION,
    label: M.COLLECTION_DATE,
  },
  {
    value: DATE_TYPES.DELIVERY,
    label: M.DELIVERY_DATE,
  },
  {
    value: DATE_TYPES.SHIPPED,
    label: M.SHIPPED_DATE,
  },
  {
    value: DATE_TYPES.TARGET_DELIVERY,
    label: M.TARGET_DELIVERY_DATE,
  },
];

export const COMMON_SEARCH_FIELDS = [Fields.ACCOUNT, Fields.TYPE];

export const SEARCH_TYPE_FIELDS_MAP = {
  [Fields.DELIVERY_REFERENCE]: [Fields.DELIVERY_REFERENCE],
  [Fields.POSTCODE]: [Fields.POSTCODE],
  [Fields.COLLECTION_CODE]: [Fields.COLLECTION_CODE],
  [Fields.CASE_CODE]: [Fields.CASE_CODE],
  [Fields.REFERENCE]: [Fields.REFERENCE, Fields.POSTCODE_CUSTOMER_REFERENCE],
  [Fields.EMAIL]: [Fields.EMAIL, Fields.POSTCODE_EMAIL],
  [Fields.PHONE]: [Fields.PHONE, Fields.POSTCODE_PHONE],
  [Fields.DATE_TYPE]: [
    Fields.DATE_TYPE,
    Fields.POSTCODE_DATE,
    Fields.FROM_DATE,
    Fields.TO_DATE,
  ],
};

export const SEARCH_REQUEST_FIELDS = [
  Fields.ACCOUNT,
  Fields.REFERENCE,
  Fields.DELIVERY_REFERENCE,
  Fields.EMAIL,
  Fields.PHONE,
  Fields.POSTCODE,
  Fields.COLLECTION_CODE,
  Fields.CASE_CODE,
  Fields.COLLECTION_START_DATE,
  Fields.COLLECTION_END_DATE,
  Fields.DELIVERY_START_DATE,
  Fields.DELIVERY_END_DATE,
  Fields.SHIPPED_START_DATE,
  Fields.SHIPPED_END_DATE,
  Fields.TARGET_START_DATE,
  Fields.TARGET_END_DATE,
];

export const POSTCODE_FIELDS = [
  Fields.POSTCODE,
  Fields.POSTCODE_EMAIL,
  Fields.POSTCODE_DATE,
  Fields.POSTCODE_PHONE,
  Fields.POSTCODE_CUSTOMER_REFERENCE,
];

export const FilterFields = {
  ACCOUNT_CODE: "accountCode",
  COLLECTION_NUMBER: "collectionNumber",
  COLLECT_COUNTRY: "collectCountry",
  SORT_BY: "sortBy",
  PRODUCT: "productCode",
  SERVICE: "serviceCode",
  DELIVERY_DEPOT: "deliveryDepot",
  OFD_TODAY: "ofdToday",
  DELIVERY_COUNTRY: "deliveryCountryCode",
  POSTCODE: "deliveryPostcode",
  DESTINATION: "destinationTypeCode",
  RANGE: "range",
  DELIVERED: "undelivered",
  DIRECTION: "directionCode",
  OVERDUE: "searchDays",
  EXCEPTIONS: "stateReasonCode",
};

export const SEND_EMAIL_FIELDS = {
  EMAIL: "email",
  SUBJECT: "subject",
  MESSAGE: "message",
  ATTACHMENTS: "attachments",
};

export const SEARCH_TO_FILTER_MAP = {
  [Fields.ACCOUNT]: FilterFields.ACCOUNT_CODE,
  [Fields.POSTCODE]: FilterFields.POSTCODE,
};

export const DASHBOARD_SEARCH_TO_FILTER_MAP = {
  [Fields.ACCOUNT]: FilterFields.ACCOUNT_CODE,
};

export const ReturnToConsignor = {
  DESCRIPTION: "deliveryAddressDescription",
  CITY: "deliveryCity",
  COUNTRY_CODE: "deliveryCountryCode",
  COUNTRY_NAME: "deliveryCountryName",
  COUNTY: "deliveryCounty",
  POSTCODE: "deliveryPostcode",
  STREET: "deliveryStreet",
  SUBURB: "deliverySuburb",
  NAME: "deliveryName",
  TOWN: "deliveryTown",
  REF: "sendersReference",
  INSTRUCTIONS: "instructions",
  IS_INTERNATIONAL_PARCEL: "isInternationalParcel",
  ALL_CHECKBOXES: "allCheckboxes",
  PARCEL_CODE: "parcelCode",
  CONTACT_NAME: "contactName",
  EMAIL: "email",
  MOBILE: "mobile",
  ADDRESS: "address",
};
export const AdjustService = {
  REF: "sendersReference",
  INSTRUCTIONS: "instructions",
  DELIVERY_DATE: "deliveryDate",
  PARCEL_CODE: "parcelCode",
  SERVICE: "serviceCode",
  FREE_OF_CHARGE: "freeOfCharge",
  ALL_CHECKBOXES: "allCheckboxes",
  CONTACT_NAME: "contactName",
  EMAIL: "email",
  MOBILE: "mobile",
  ADDRESS: "address",
};

export const CollectFromPickupShop = {
  CONTACT_NAME: "contactName",
  EMAIL: "email",
  MOBILE: "mobile",
  PICKUP_LOCATION_CODE: "pickupLocationCode",
  PICKUP_DATE: "pickupDate",
};

export const alternativeAddress = {
  ALL_CHECKBOXES: "allCheckboxes",
  COUNTRY: "country",
  POSTCODE: "postcode",
  NAME: "name",
  STREET: "street",
  SUBURB: "suburb",
  TOWN: "town",
  CITY: "city",
  COUNTY: "county",
  DELIVERY_DATE: "deliveryDate",
  REFERENCE_CHANGE_ADDRESS: "sendersReference",
  INSTRUCTIONS: "instructions",
  AGREEMENT: "agreement",
};

export const ChangeDeliveryDate = {
  DELIVERY_DATE: "deliveryDate",
  INSTRUCTIONS: "instructions",
  CONTACT_NAME: "contactName",
  EMAIL: "email",
  MOBILE: "mobile",
};

export const UpdateContactDetails = {
  CONTACT_NAME: "contactName",
  EMAIL: "email",
  MOBILE: "mobile",
};

export const CollectFromDepot = {
  CONTACT_NAME: "contactName",
  EMAIL: "email",
  MOBILE: "mobile",
  DIARY_DATE: "diaryDate",
  DIARY_TIME: "diaryTime",
};

export const alternativeAddressUpgrade = {
  ALL_CHECKBOXES: "allCheckboxes",
  COUNTRY: "country",
  POSTCODE: "postcode",
  NAME: "name",
  STREET: "street",
  SUBURB: "suburb",
  TOWN: "town",
  CITY: "city",
  COUNTY: "county",
  DELIVERY_DATE: "deliveryDate",
  DELIVERY_SERVICE: "deliveryService",
  REFERENCE_CHANGE_ADDRESS: "sendersReference",
  INSTRUCTIONS: "instructions",
  AGREEMENT: "agreement",
};

export const disposeRequest = {
  AGREEMENT: "agreement",
};

export const serviceDisruption = {
  POSTCODE: "postcode",
};

export const OVERDUE_DAYS = [
  { label: M.OVERDUE_DAYS, value: M.ALL },
  { label: "1 Day", value: "1" },
  { label: "2 Days", value: "2" },
  { label: "3 Days", value: "3" },
  { label: "4 Days", value: "4" },
  { label: "5 Days", value: "5" },
  { label: "6 Days", value: "6" },
  { label: "7 Days", value: "7" },
  { label: "8 Days", value: "8" },
  { label: "9 Days", value: "9" },
  { label: "10 Days", value: "10" },
];
export const OFD_DELIVERED_VALUES = [
  {
    value: M.DASH_DASH,
    label: M.DASH_DASH,
  },
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export const emptyStringLIstFilters = [
  M.ALL,
  M.DASH_DASH,
  M.NOT_SPECIFIED_VALUE,
];

export const EMPTY_FIELD_NAMES_FILTERS = [
  FilterFields.PRODUCT,
  FilterFields.DELIVERY_COUNTRY,
  FilterFields.ACCOUNT_CODE,
  FilterFields.SERVICE,
  FilterFields.COLLECT_COUNTRY,
  FilterFields.DELIVERY_DEPOT,
  FilterFields.DESTINATION,
  FilterFields.RANGE,
  FilterFields.SORT_BY,
  FilterFields.DIRECTION,
  FilterFields.OFD_TODAY,
  FilterFields.DELIVERED,
];

export const FILTER_INITIAL_VALUES = {
  [FilterFields.PRODUCT]: M.ALL,
  [FilterFields.DELIVERY_COUNTRY]: M.ALL,
  [FilterFields.ACCOUNT_CODE]: M.ALL,
  [FilterFields.SERVICE]: M.ALL,
  [FilterFields.COLLECT_COUNTRY]: M.ALL,
  [FilterFields.DELIVERY_DEPOT]: M.ALL,
  [FilterFields.DESTINATION]: M.ALL,
  [FilterFields.RANGE]: M.ALL,
  [FilterFields.SORT_BY]: M.NOT_SPECIFIED_VALUE,
  [FilterFields.DIRECTION]: M.ALL,
  [FilterFields.OFD_TODAY]: M.DASH_DASH,
  [FilterFields.DELIVERED]: M.DASH_DASH,
  [FilterFields.OVERDUE]: M.ALL,
  [FilterFields.EXCEPTIONS]: M.ALL,
};
