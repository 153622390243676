export const INTERNAL_COUNTRY_CODES = {
  UK: "UK",
  EI: "EI",
  US: "US",
};

export const DESTINATION_CODES = {
  DOMESTIC: "D",
  INTERNATIONAL: "I",
};
