import { ActionTypes } from "./actions";

export const initialState = {
  products: [],
  services: [],
  countries: [],
  depots: [],
  ranges: {},
  exception: [],
  alternativeAddressCountries: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH.SUCCESS: {
      const { products, services, countries, depots } = action.payload.data;
      return {
        ...state,
        products,
        services,
        countries,
        depots,
      };
    }
    case ActionTypes.FETCH_RANGES.SUCCESS: {
      const { accountCode, data } = action.payload;
      return {
        ...state,
        ranges: {
          ...state.ranges,
          [accountCode]: data,
        },
      };
    }
    case ActionTypes.FETCH_EXCEPTION.SUCCESS: {
      return {
        ...state,
        exception: action.payload,
      };
    }
    case ActionTypes.FETCH_COUNTRIES.SUCCESS: {
      return {
        ...state,
        alternativeAddressCountries: action.payload.data,
      };
    }
    default:
      return state;
  }
};
