import { map } from "lodash";

import {
  SAFE_PLACE_NAME,
  SAFE_PLACE_TYPE,
  utils,
} from "@dpdgroupuk/redback-enums";

import * as M from "../constants/strings";
import { DESTINATION_CODES } from "../constants/address";

export const SORT_BY = [
  utils.createEnum(M.NOT_SPECIFIED_VALUE, M.NOT_SPECIFIED),
  utils.createEnum("CUSTOMER.CODE-A", "Account No (ascending)"),
  utils.createEnum("CUSTOMER.CODE-D", "Account No (descending)"),
  utils.createEnum("COLLECT.DATE-A", "Collection Date (ascending)"),
  utils.createEnum("COLLECT.DATE-D", "Collection Date (descending)"),
  utils.createEnum("I_CON_NO-A", "Consignment No (ascending)"),
  utils.createEnum("I_CON_NO-D", "Consignment No (descending)"),
  utils.createEnum("DEL.DEPOT-A", "Delivery Depot (ascending)"),
  utils.createEnum("DEL.DEPOT-D", "Delivery Depot (descending)"),
  utils.createEnum("I_PARCEL_NO-A", "Parcel No (ascending)"),
  utils.createEnum("I_PARCEL_NO-D", "Parcel No (descending)"),
];

export const DESTINATIONS = [
  {
    value: M.ALL,
    label: M.ALL_DESTINATIONS,
  },
  {
    value: DESTINATION_CODES.DOMESTIC,
    label: "Domestic",
  },
  {
    value: DESTINATION_CODES.INTERNATIONAL,
    label: "International",
  },
];
export const DIRECTIONS = [
  {
    value: M.ALL,
    label: M.ALL_DIRECTIONS,
  },
  {
    value: "O",
    label: "Outbound",
  },
  {
    value: "I",
    label: "Inbound",
  },
];

export const getSafePlaceObjects = () =>
  map(
    [
      SAFE_PLACE_TYPE.FPC,
      SAFE_PLACE_TYPE.GAR,
      SAFE_PLACE_TYPE.OBL,
      SAFE_PLACE_TYPE.RPC,
      SAFE_PLACE_TYPE.OTH,
    ],
    type => ({
      name: SAFE_PLACE_NAME[type],
      type,
    })
  );
