import { useMemo } from "react";

import { has, isEmpty, omitBy } from "lodash";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, withProps } from "recompose";
import { change, Field, propTypes, reduxForm, touch } from "redux-form";

import { Button, FormControl, Popover } from "@dpdgroupuk/mydpd-ui";
import { COUNTRIES_ENUM } from "@dpdgroupuk/redback-enums";

import CardWithTitle from "../../components/CardWithTitle";
import { valuesPropType } from "../../components/Dropdown";
import {
  DELIVERIES_SEARCH_FILTER_FORM,
  EMPTY_FIELD_NAMES_FILTERS,
  FilterFields,
  OFD_DELIVERED_VALUES,
  OVERDUE_DAYS,
  INTERNATIONAL_POSTCODE_MAX_LENGTH,
} from "../../constants/forms";
import * as M from "../../constants/strings";
import PostcodeFinderInput from "../../features/PostcodeFinder";
import {
  deleteDisabledValues,
  emptyValuesMapper,
} from "../../models/dashboard";
import { DESTINATIONS, DIRECTIONS, SORT_BY } from "../../models/enum";
import * as validators from "../../models/validators";
import { getAccounts } from "../../pages/Dashboard/selectors";
import { ReferenceActions, ReferenceSelectors } from "../../redux/references";
import { getDisabledFields } from "../../utils/query";
import {
  changeIrelandCountryCode,
  mapRangeReference,
} from "../../utils/reference";
import styles from "./FilterForm.module.scss";

const FilterForm = ({
  handleSubmit,
  onReset,
  onFilter,
  onFieldEntry,
  submitting,
  invalid,
  sortBy,
  countries,
  accounts,
  emptyFilters,
  dirty,
  error,
  products,
  services,
  directions,
  depots,
  destinations,
  range,
  overdueDays,
  exception,
  title,
  location,
  onSelectAddressFromModal,
  disabledFields = [],
}) => {
  const allProducts = useMemo(
    () => [{ label: M.ALL_PRODUCTS, value: M.ALL }, ...products],
    [products]
  );
  const allCountries = useMemo(
    () => [{ label: M.ALL_COUNTRIES, value: M.ALL }, ...countries],
    [countries]
  );
  const allServices = useMemo(
    () => [{ label: M.ALL_SERVICES, value: M.ALL }, ...services],
    [services]
  );
  const allDepots = useMemo(
    () => [{ label: M.ALL_DEPOTS, value: M.ALL }, ...depots],
    [depots]
  );
  const allException = useMemo(
    () => [{ label: M.ALL_EXCEPTIONS, value: M.ALL }, ...exception],
    [exception]
  );
  const emptyFields = useMemo(() => {
    const result = [...EMPTY_FIELD_NAMES_FILTERS];
    if (location.pathname === M.OVERDUE_PATH) {
      result.push(FilterFields.OVERDUE);
    }
    if (location.pathname === M.EXCEPTION_PATH) {
      result.push(FilterFields.EXCEPTIONS);
    }
    return result;
  }, [location]);

  return (
    <CardWithTitle title={title}>
      <Container fluid className="p-0">
        <Row>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.PRODUCT}
              name={FilterFields.PRODUCT}
              values={allProducts}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.DELIVERY_COUNTRY}
              name={FilterFields.DELIVERY_COUNTRY}
              values={allCountries}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.ACCOUNT}
              name={FilterFields.ACCOUNT_CODE}
              values={accounts}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
              disabled={
                disabledFields.includes(FilterFields.ACCOUNT_CODE) ||
                accounts.length <= 1
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.SERVICE}
              name={FilterFields.SERVICE}
              values={allServices}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              name={FilterFields.POSTCODE}
              component={PostcodeFinderInput}
              label={M.POSTCODE}
              maxLength={INTERNATIONAL_POSTCODE_MAX_LENGTH}
              id={FilterFields.POSTCODE}
              onSelectionChange={onSelectAddressFromModal}
              onBlur={onFieldEntry}
              disabled={disabledFields.includes(FilterFields.POSTCODE)}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.COLLECTION_COUNTRY}
              name={FilterFields.COLLECT_COUNTRY}
              values={allCountries}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.DELIVERY_DEPOT}
              name={FilterFields.DELIVERY_DEPOT}
              values={allDepots}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.DESTINATION}
              name={FilterFields.DESTINATION}
              values={destinations}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.RANGE}
              name={FilterFields.RANGE}
              values={range}
              onBlur={onFieldEntry}
              disabled={range.length === 1}
              textTransform={M.UPPERCASE}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Field
              component={FormControl.Dropdown}
              label={M.OFD_TODAY}
              name={FilterFields.OFD_TODAY}
              values={OFD_DELIVERED_VALUES}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={2}>
            <Field
              component={FormControl.Dropdown}
              label={M.DELIVERED}
              name={FilterFields.DELIVERED}
              values={OFD_DELIVERED_VALUES}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.SORT_BY}
              name={FilterFields.SORT_BY}
              values={sortBy}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              component={FormControl.Dropdown}
              label={M.DIRECTION}
              name={FilterFields.DIRECTION}
              values={directions}
              onBlur={onFieldEntry}
              textTransform={M.UPPERCASE}
            />
          </Col>
        </Row>
        {location.pathname === M.OVERDUE_PATH && (
          <Row>
            <Col xs={12} md={4}>
              <Field
                component={FormControl.Dropdown}
                label={M.OVERDUE}
                name={FilterFields.OVERDUE}
                values={overdueDays}
                onBlur={onFieldEntry}
                textTransform={M.UPPERCASE}
              />
            </Col>
          </Row>
        )}

        {location.pathname === M.EXCEPTION_PATH && (
          <Row>
            <Col xs={12} md={4}>
              <Field
                component={FormControl.Dropdown}
                label={M.EXCEPTION}
                name={FilterFields.EXCEPTIONS}
                values={allException}
                onBlur={onFieldEntry}
                textTransform={M.UPPERCASE}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="text-right">
            <Button
              className={styles.resetButton}
              variant="dark"
              onClick={onReset}
              disabled={emptyFilters && !dirty}
            >
              {M.RESET}
            </Button>
            <Popover isOpen={error} content={error}>
              <Button
                onClick={handleSubmit(values =>
                  onFilter(
                    omitBy(
                      {
                        ...deleteDisabledValues(values, disabledFields),
                        ...emptyValuesMapper(values, emptyFields),
                      },
                      isEmpty
                    )
                  )
                )}
                disabled={submitting || invalid}
              >
                {M.FILTER}
              </Button>
            </Popover>
          </Col>
        </Row>
      </Container>
    </CardWithTitle>
  );
};

FilterForm.defaultProps = {
  onFilter: () => null,
  onReset: () => null,
  onFieldEntry: () => null,
  sortBy: SORT_BY,
  emptyFilters: true,
  countries: changeIrelandCountryCode(COUNTRIES_ENUM),
  directions: DIRECTIONS,
  depots: [],
  destinations: DESTINATIONS,
  ranges: [],
  overdueDays: [],
  exception: [],
  searchFilterMap: {},
};

FilterForm.propTypes = {
  ...propTypes,
  emptyFilters: PropTypes.bool,
  accounts: PropTypes.array,
  overdueDays: PropTypes.array,
  title: PropTypes.string,
  exception: PropTypes.array,
  countries: valuesPropType,
  directions: valuesPropType,
  destinations: valuesPropType,
  sortBy: valuesPropType,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
  onFieldEntry: PropTypes.func,
  onSelectAddressFromModal: PropTypes.func,
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  searchFilterMap: PropTypes.object,
};

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: DELIVERIES_SEARCH_FILTER_FORM,
    validate: validators.filterParcels,
    onChange: (
      // TODO: find usage onChange
      { accountCode },
      dispatch,
      { change, ranges },
      { accountCode: prevAccountCode }
    ) => {
      if (prevAccountCode && prevAccountCode !== accountCode) {
        dispatch(change(FilterFields.RANGE, M.ALL));
        if (!isEmpty(accountCode) && !has(ranges, accountCode)) {
          dispatch(ReferenceActions.fetchRanges(accountCode));
        }
      }
    },
  }),
  connect(
    state => ({
      ranges: ReferenceSelectors.getRanges(state),
      range: mapRangeReference(ReferenceSelectors.getRange(state) || {}),
      overdueDays: OVERDUE_DAYS,
      exception: ReferenceSelectors.getException(state),
      accounts: getAccounts(state),
    }),
    (dispatch, { form }) => ({
      onSelectAddressFromModal: (
        { postcode },
        fieldName = FilterFields.POSTCODE
      ) => dispatch(change(form, fieldName, postcode)),
      setAccountCode: accountCode => {
        dispatch(change(form, FilterFields.ACCOUNT_CODE, accountCode));
        dispatch(touch(form, FilterFields.ACCOUNT_CODE));
      },
    })
  ),
  withRouter,
  withProps(({ location, searchFilterMap }) => ({
    disabledFields: getDisabledFields(location, searchFilterMap),
  }))
)(FilterForm);
